<!-- @format -->

<template>
  <b-card>
    <b-row class="invoice-add">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="6">
        <validation-observer ref="orderEdit">
          <b-form class="auth-register-form mt-2" @submit.prevent="submitAddProduct">
            <!-- username -->
            <b-form-group label="订单编号" label-cols="1" label-cols-md="2" content-cols="6" content-cols-md="10">
              <validation-provider #default="{ errors }" name="订单编号" rules="required">
                <b-form-input v-model="detailInfo.number" :state="errors.length > 0 ? false : null" placeholder="请输入订单编号" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="订单TP" label-cols="1" label-cols-md="2" content-cols="6" content-cols-md="10">
              <validation-provider #default="{ errors }" name="订单TP" rules="required">
                <b-form-input v-model="detailInfo.order_tp" :state="errors.length > 0 ? false : null" placeholder="请输入订单TP值" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="会员选择" label-cols="1" label-cols-md="2" content-cols="6" content-cols-md="10">
              <validation-provider #default="{ errors }" name="会员" rules="required">
                <v-select
                  @search="fetchVipData"
                  v-model="detailInfo.user_id"
                  :tabindex="1"
                  label="real_name"
                  :reduce="item => item.id"
                  placeholder="请选择会员"
                  :state="errors.length > 0 ? false : null"
                  :class="[errors.length > 0 ? 'style-chooser' : 'style-chooser-none']"
                  :options="vipList"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="成交时间" label-cols="1" label-cols-md="2" content-cols="6" content-cols-md="10">
              <validation-provider #default="{ errors }" name="成交时间" rules="required">
                <flat-pickr v-model="detailInfo.deal_time" class="form-control" :config="timePickerConfig" placeholder="请选择成交时间" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="产品" label-cols="1" label-cols-md="2" content-cols="6" content-cols-md="10">
              <validation-provider #default="{ errors }" name="公司品牌" rules="required">
                <vSelect
                  v-model="detailInfo.product_id"
                  placeholder="请选择公司"
                  label="title"
                  :reduce="item => item.id"
                  :filterable="false"
                  :options="productList"
                  :class="[errors.length > 0 ? 'style-chooser' : 'style-chooser-none']"
                  :state="errors.length > 0 ? false : null"
                >
                  <li slot="list-footer" class="pagination_vselest">
                    <b-button :disabled="!hasPrevPage" @click.prevent="casePagae--">上一页</b-button>
                    <b-button :disabled="!hasNextPage" @click.prevent="casePagae++">下一页</b-button>
                  </li>
                </vSelect>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!--            <b-form-group label="状态" label-cols="1" label-cols-md="2" content-cols="6" content-cols-md="10">-->
            <!--              <validation-provider #default="{ errors }" name="状态" vid="gender" rules="required">-->
            <!--                <v-select-->
            <!--                  v-model="detailInfo.status"-->
            <!--                  :tabindex="1"-->
            <!--                  :reduce="item => item.value"-->
            <!--                  :placeholder="'请选择状态'"-->
            <!--                  :state="errors.length > 0 ? false : null"-->
            <!--                  :class="[errors.length > 0 ? 'style-chooser' : 'style-chooser-none']"-->
            <!--                  :options="[-->
            <!--                    { label: '已提交', value: 1 },-->
            <!--                    { label: '已处理', value: 2 },-->
            <!--                    { label: '已生效', value: 3 },-->
            <!--                  ]" />-->
            <!--                <small class="text-danger">{{ errors[0] }}</small>-->
            <!--              </validation-provider>-->
            <!--            </b-form-group>-->
            <!--确认-->
            <b-form-group label-cols="1" label-cols-md="2" content-cols="6" content-cols-md="10">
              <b-button type="submit" variant="success" :disabled="isLoading">确认</b-button>
            </b-form-group>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { BRow, BCol, BButton, BForm, BFormGroup, BFormInput } from 'bootstrap-vue'
import store from '@/store/index'
import { onMounted, reactive, ref, computed, getCurrentInstance } from '@vue/composition-api'
import ListManagerModule from '@/views/GeneralManagement/ProductManager/List/ListManagerModule'
import { required, number } from '@core/utils/validations/validations'

import { useToast } from 'vue-toastification/composition'

import flatPickr from 'vue-flatpickr-component'
import zh from 'flatpickr/dist/l10n'
import axiosIns from '@/libs/axios'
import orderModule from '@/views/vip_in/Order/OrderList/OrderManagerList'

export default {
  name: 'OrderEdit',
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    vSelect,
    ValidationObserver,
    BButton,
    flatPickr,
  },

  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'user-order'

    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, orderModule)

    const { proxy } = getCurrentInstance()
    const toast = useToast()

    const isLoading = ref(false)
    const timePickerConfig = {
      enableTime: true,
      dateFormat: 'Y-m-d H:i:ss',
      locale: zh.zh, // locale for this instance only
    }
    const detailInfo = computed(() => store.state['user-order'].orderDetail)
    const productList = reactive([])
    const casePagae = ref(1)
    const total = ref(0)
    const hasPrevPage = computed(() => casePagae.value > 1)
    const hasNextPage = computed(() => casePagae.value < Math.ceil(total.value / 20))

    // 会员列表
    let vipList = reactive([])

    // 获取产品列表
    const fetchProdList = () => {
      if (!store.hasModule('product-list')) store.registerModule('product-list', ListManagerModule)
      store
        .dispatch('product-list/fetchDataList', {
          pageSize: 20,
          page: casePagae.value,
        })
        .then(response => {
          productList.push(...response.data.list)
          total.value = response.data.total
        })
    }

    const fetchVipData = (search, loading) => {
      if (search.length) {
        loading(true)
        axiosIns.get('/user/list', { params: { name: search } }).then(response => {
          if (response.code === 0) {
            vipList = response.data.list
            loading(false)
          }
        })
      }
    }

    const submitAddProduct = () => {
      proxy.$refs.orderEdit.validate().then(valid => {
        if (valid) {
          // 合并参数,去除不存在的值
          const editData = {
            product_id: '',
            status: '',
            number: '',
            deal_time: '',
            order_tp: '',
            user_id: '',
            id: '',
          }
          Object.keys(editData).forEach(key => {
            if (detailInfo.value[key]) {
              editData[key] = detailInfo.value[key]
            }
          })
          store
            .dispatch('user-order/orderUpdate', editData)
            .then(response => {
              if (response.code === 0) {
                toast.success(response.msg || '添加成功')
                setTimeout(() => {
                  isLoading.value = false
                  proxy.$router.back()
                }, 1000)
              } else {
                isLoading.value = false
                toast.error(response.msg)
              }
            })
            .catch(() => {
              toast.error('添加失败')
              isLoading.value = false
            })
        } else {
          toast('请填写完整信息', { type: 'error' })
        }
      })
    }

    onMounted(() => {
      fetchProdList()
      axiosIns.get('/user/list').then(response => {
        if (response.code === 0) {
          vipList.push(...response.data.list)
        }
      })
    })
    return {
      detailInfo,
      timePickerConfig,
      isLoading,
      fetchVipData,
      submitAddProduct,
      vipList,
      productList,
      hasPrevPage,
      hasNextPage,
      casePagae,
      required,
      number,
    }
  },
  beforeRouteEnter(to, from, next) {
    // 进入页面前检查detailInfo是否存在id
    next(vm => {
      console.log(vm.detailInfo)
      if (!vm.detailInfo?.id) {
        vm.$bvToast.toast('参数错误!请重新选择', {
          title: '提示',
          variant: 'warning',
          solid: true,
          appendToast: false,
          autoHideDelay: 2000,
        })
        setTimeout(() => {
          vm.$router.back()
        }, 2000)
      } else {
        next()
      }
    })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
