var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-row',{staticClass:"invoice-add"},[_c('b-col',{attrs:{"cols":"6"}},[_c('validation-observer',{ref:"orderEdit"},[_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.submitAddProduct($event)}}},[_c('b-form-group',{attrs:{"label":"订单编号","label-cols":"1","label-cols-md":"2","content-cols":"6","content-cols-md":"10"}},[_c('validation-provider',{attrs:{"name":"订单编号","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"请输入订单编号"},model:{value:(_vm.detailInfo.number),callback:function ($$v) {_vm.$set(_vm.detailInfo, "number", $$v)},expression:"detailInfo.number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"订单TP","label-cols":"1","label-cols-md":"2","content-cols":"6","content-cols-md":"10"}},[_c('validation-provider',{attrs:{"name":"订单TP","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"请输入订单TP值"},model:{value:(_vm.detailInfo.order_tp),callback:function ($$v) {_vm.$set(_vm.detailInfo, "order_tp", $$v)},expression:"detailInfo.order_tp"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"会员选择","label-cols":"1","label-cols-md":"2","content-cols":"6","content-cols-md":"10"}},[_c('validation-provider',{attrs:{"name":"会员","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:[errors.length > 0 ? 'style-chooser' : 'style-chooser-none'],attrs:{"tabindex":1,"label":"real_name","reduce":function (item) { return item.id; },"placeholder":"请选择会员","state":errors.length > 0 ? false : null,"options":_vm.vipList},on:{"search":_vm.fetchVipData},model:{value:(_vm.detailInfo.user_id),callback:function ($$v) {_vm.$set(_vm.detailInfo, "user_id", $$v)},expression:"detailInfo.user_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"成交时间","label-cols":"1","label-cols-md":"2","content-cols":"6","content-cols-md":"10"}},[_c('validation-provider',{attrs:{"name":"成交时间","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.timePickerConfig,"placeholder":"请选择成交时间"},model:{value:(_vm.detailInfo.deal_time),callback:function ($$v) {_vm.$set(_vm.detailInfo, "deal_time", $$v)},expression:"detailInfo.deal_time"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"产品","label-cols":"1","label-cols-md":"2","content-cols":"6","content-cols-md":"10"}},[_c('validation-provider',{attrs:{"name":"公司品牌","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vSelect',{class:[errors.length > 0 ? 'style-chooser' : 'style-chooser-none'],attrs:{"placeholder":"请选择公司","label":"title","reduce":function (item) { return item.id; },"filterable":false,"options":_vm.productList,"state":errors.length > 0 ? false : null},model:{value:(_vm.detailInfo.product_id),callback:function ($$v) {_vm.$set(_vm.detailInfo, "product_id", $$v)},expression:"detailInfo.product_id"}},[_c('li',{staticClass:"pagination_vselest",attrs:{"slot":"list-footer"},slot:"list-footer"},[_c('b-button',{attrs:{"disabled":!_vm.hasPrevPage},on:{"click":function($event){$event.preventDefault();_vm.casePagae--}}},[_vm._v("上一页")]),_c('b-button',{attrs:{"disabled":!_vm.hasNextPage},on:{"click":function($event){$event.preventDefault();_vm.casePagae++}}},[_vm._v("下一页")])],1)]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols":"1","label-cols-md":"2","content-cols":"6","content-cols-md":"10"}},[_c('b-button',{attrs:{"type":"submit","variant":"success","disabled":_vm.isLoading}},[_vm._v("确认")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }